module.exports = {
  siteTitle: 'A&J Dock Builders',
  manifestName: 'A&J',
  manifestShortName: 'A&J Landing',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/anchor.png',
  pathPrefix: `/gatsby-starter-spectral/`,
  headingType: 'logo',
  headingImage: 'src/assets/img/anchor.png',
  subHeading: 'We build docks',
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/AJDockBuilders',
    }
  ],
};

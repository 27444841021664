import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <footer
      id="footer"
    >
      <ul
        className="icons"
      >
        {
          config.socialLinks.map(social => {
            const {style, icon, name, url} = social;
            return (
              <li
                key={url}
              >
                <a
                  href={url}
                  className={`icon ${style} ${icon}`}
                  target="_blank"
                  rel="noreferrer"
                >
                <span
                  className="label"
                >{name}</span>
                </a>
              </li>
            );
          })
        }
      </ul>
      <ul className="copyright">
        <li>&copy; 2020-2021 A&J Dock Builders</li>
      </ul>
    </footer>
  );
}
